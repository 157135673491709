import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import { Box, styled } from '@mui/material'
import Drawer from '@mui/material/Drawer'
import { CSSProperties } from '@mui/styled-engine'
import Logo from 'assets/images/logo.png'
import { ReactNode } from 'react'
import IButton from '../../IButton'

export const drawerWidth = 240

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...(theme.mixins.toolbar as CSSProperties)
}))

export type IProps = {
  open?: boolean
  children: ReactNode
  toggleSidebar?: () => void
}

const DrawerLeft: React.FC<IProps> = ({ open, children, toggleSidebar }) => {
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          border: 'none'
        }
      }}
      anchor="left"
      open={open}
      onClose={toggleSidebar}
    >
      <DrawerHeader>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <img src={Logo} alt="logo" />
          <IButton onClick={toggleSidebar} sx={{ margin: '0 0 8px 12px' }}>
            <ChevronLeftIcon />
          </IButton>
        </Box>
      </DrawerHeader>
      {children}
    </Drawer>
  )
}

export { DrawerLeft }
